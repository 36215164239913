import React from 'react'
import logo from '../assets/images/logo.png';
import InfoBoxes from './InfoBoxes';
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="bg-gray-200 py-4 mb-5">
      <InfoBoxes />
      <div
        className="container mx-auto flex flex-col md:flex-row items-center justify-between px-4"
      >
        <div className="mb-4 md:mb-0" >
          <img src={logo} alt="Logo" className="h-8 w-auto" />
        </div>
        <div>
          <p className="text-sm text-gray-500 mt-2 md:mt-0">
            <Link to="/privacy"    >Privacy </Link>
            &copy; {currentYear} Myhome Navadweepa. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer