import React from 'react';

const HomePage = () => {
    return (
        <div>
            <section className="bg-grey-400 py-20 mb-4 ">           
                <div
                    className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col items-center"
                >
                    <div className="text-center">
                       
                       {/*  <h1
                            className="text-4xl font-extrabold text-red-500 sm:text-5xl md:text-6xl"
                        >
                            My Home Navadweepa
                        </h1> */}
                        <p className="my-1 text-3xl text-red-500">
                            Welcome to the Island of Tranquility
                        </p>
                    </div>
                    <div className='text-center text-md text-black-900'>
                        <p>Located at the beginning of Hitech City, this gated community is spread over 9 acres of land with 550 apartments.

                        </p>
                        <p>My Home Navadweepa is near Hitech City Metro Station and beside Hotel Avasa. It is about a kilometer from Shilparamam.

                            The apartments are spread over Four 9 Storeyed blocks.
                            The blocks are named after the Five Elements of Nature : Varuna, Teja, Prithvi and Vayu with Club House Block known as Akash.
                            The configurations available are 2 Bedroom (120 units), 3 Bedroom (400 units) and Duplex (30 units).</p>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default HomePage