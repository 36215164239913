
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import index from './index.css';
import CommitteePage from '../src/components/Committee';
import FacilitiesPage from '../src/components/Facilities';
import ContactPage from '../src/components/Contacts';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

export const metadata = {
  title: 'Myhome Navadweepa',
  description: 'Luxury Gated Community',
  keywords: 'myhome Navadweepa, myhome, Navadeepa, rental, find rentals, find properties'
};

const App = () => {
  return (
    <div >
      <Navbar />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/committee" element={<CommitteePage />} />
            <Route path="/facilities" element={<FacilitiesPage />} />
            <Route path="/contact" element={<ContactPage />} />
        </Routes>
      <Footer />

    </div>
  );
}
export default App;
