import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { GoogleOAuthProvider} from "@react-oauth/google";
import { BrowserRouter as Router } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
        <App />
        <GoogleOAuthProvider clientId="1055448081667-k8ti1js18jijfoj2o9rkfm08f9d0eclu.apps.googleusercontent.com" >
        </GoogleOAuthProvider>  
    </Router> 
  </React.StrictMode>
);