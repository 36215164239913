import React from 'react';

const ContactCard = ({ contact }) => {

    return (
        <div className="rounded-xl shadow-md relative">            
            <div className="p-4">
                <div className="text-left md:text-center lg:text-left mb-6">
                    <div className="text-gray-600">{contact.title}</div>
                </div>
                <h3
                    className="absolute top-[10px] right-[10px] bg-white px-4 py-2 rounded-lg text-blue-500 font-bold text-right md:text-center lg:text-right"
                >
                    {contact.name}
                </h3>
                <div className="border border-gray-100 mb-1"></div>
            </div>
        </div>
    )
}

export default ContactCard