import React from 'react';

const FacilityCard = ({ facility }) => {

    return (
        <div className="rounded-xl shadow-md relative">
           
            <div className="p-4">
                <div className="text-left md:text-center lg:text-left mb-6">                    
                </div>
                <h3
                    className="absolute top-[5px] right-[10px] bg-grey px-4 py-2 rounded-lg text-blue-500 font-bold text-left md:text-center lg:text-left"
                >
                    {facility.title}
                </h3>
                <div className="border border-gray-100 mb-1"></div>
            </div>
        </div>
    )
}

export default FacilityCard