import { Link } from "react-router-dom";
import banner from "../assets/images/banner.jpg";

const Navbar = () => {

  return (
    
    <div className="text-center space-x-2 font-semibold text-xl">
		  <div className="mb-4 md:mb-0">
          <img src= { banner } alt="Banner" className="h-40 w-auto align-baseline mb-2 ml-4 mt-2" />
      </div>

      <Link to="/">Home</Link>
      <Link to="/committee" >Committee </Link>
      <Link to="/facilities" >Facilities </Link>
      <Link to="/contact"    >Contact </Link>

    </div>

  );
};

export default Navbar;
