import React from 'react'
import InfoBox from './InfoBox'

const infoBoxes = () => {
    return (
        <section>
            <div className="container-xl lg:container m-auto">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4 rounded-lg">
                    
                    <InfoBox
                        heading='Contact Numbers'
                        backgroundColor="bg-blue-100"
                        buttonInfo={{
                            text: 'Varuna-1199 Teja-2299 Prithvi-3399 Vayu-4499 click for more numbers...',
                            link: '/contacts',
                            backgroundColor: 'bg-blue-500',
                        }
                        }
                    >
                        Block Security Numbers
                    </InfoBox>                   
                    <InfoBox
                        heading='Email Contact'
                        backgroundColor="bg-blue-100"
                        buttonInfo={{
                            text: 'Send an email to fm@myhomenavadweepa.com',
                            link: '/',
                            backgroundColor: 'bg-blue-500',
                        }
                        }
                    >
                        Contact Management Office
                    </InfoBox>
                </div>
            </div>
        </section>
    );
}
export default infoBoxes