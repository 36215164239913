import React from 'react';
import contacts from "../store/contacts.json";
import ContactCard from './ContactCard';

const ContactPage = () => {
  return (
    <section className="px-4 py-4">
        <div className="container-xl lg:container m-auto px-4 py-4">
            <h2 className='text-center font-bold mt-5 px-4 py-4'>Contact Numbers</h2>
          {
            contacts.length === 0 ? (
              <p>No Contact Numbers Found</p>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-xl">
                
              { contacts.map((contact) => (                              
                
                <ContactCard key={ contact._id } contact = {contact} />
                 ))}
              </div> 
            )
          } 
        </div>
    </section>
  )
}

export default ContactPage