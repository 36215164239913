import React from 'react';
import members from "../store/members.json";
import MemberCard from './MemberCard';

const CommitteePage = () => {
  return (
    <section className="px-4 py-4">
        <div className="container-xl lg:container m-auto px-4 py-6">
            <h2 className='text-center font-bold mt-5 px-4 py-4'>Executive Committee 2024-26</h2>
          {
            members.length === 0 ? (
              <p>No Members Found</p>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-xl">
                
              { members.map((member) => (               
                  <MemberCard key={  member._id} member = { member }  />                
                ))}
              </div> 
            )
          } 
        </div>
    </section>
  )
}

export default CommitteePage